<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card :loading="loading" :disabled="loading" loader-height="2">
          <v-card-text class="pb-0">
            <div class="d-flex align-center">
              <v-btn
                rounded
                color="primary"
                v-if="$nova.hasRight(right, 'add')"
                @click="
                  verifyDialog = true;
                  verifyObj = {};
                  fileTotalArr = [];
                "
                elevation="0"
                small
                ><v-icon>mdi-plus</v-icon>Add</v-btn>
              <v-btn icon small class="ml-1" @click="refreshData()">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <lb-string
                class="ml-1"
                label="Search"
                outlined
                hidedetails
                v-model="seachList"
              ></lb-string>
              <v-spacer></v-spacer>
              <v-chip label small class="border-left-error" outlined>Disabled</v-chip>
            </div>
          </v-card-text>
          <v-card-text>
            <!-- <v-simple-table class="pbc-view-table" dense>
              <thead>
                <tr>
                  <th scope="#" class="px-4 text-center">#</th>
                  <th scope="Code">Code</th>
                  <th scope="Code">Name</th>
                  <th scope="Description">Description</th>
                  <th scope="Data Type">Area</th>
                  <th scope="Action">Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(v, k) in searchResult">
                  <tr :key="k">
                    <td
                      :class="`${
                        v.status
                          ? 'border-left-transparent'
                          : 'border-left-error'
                      }`"
                    >
                      <span>{{ k + 1 }}</span>
                    </td>
                    <td>
                      <span
                        v-html="$nova.getHighlitedText(v.code || '', seachList)"
                      ></span>
                    </td>
                    <td>
                      <span>{{ v.name }}</span>
                    </td>
                    <td>
                      <p class="text-md-left">{{ v.description }}</p>
                    </td>
                    <td>
                      <span>{{ (v.area_detail[0] || {}).name }}</span>
                    </td>
                    <td> -->
            <!-- <v-menu offset-y transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="view-on-hover-item-d mx-0 px-0"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon>mdi-menu</v-icon></v-btn
                          >
                        </template>
                        <v-list dense class="pa-0 border-left-default">
                          <v-list-item @click="editVerify(v)">
                            <v-list-item-title
                              ><v-icon left>mdi-pencil</v-icon
                              >Edit</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item @click="viewVerify(v)">
                            <v-list-item-title
                              ><v-icon left>mdi-eye-arrow-right</v-icon
                              >View</v-list-item-title
                            >
                          </v-list-item>

                          <v-list-item
                            v-if="v.status"
                            @click="disableVerify(v._id)"
                          >
                            <v-list-item-title
                              ><v-icon color="error" left>mdi-cancel</v-icon
                              >Disable</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item v-else @click="enableVerify(v._id)">
                            <v-list-item-title
                              ><v-icon left color="success"
                                >mdi-check-bold </v-icon
                              >Enable</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu> -->

            <!-- <div class="d-flex justify-center">
                        <v-tooltip bottom content-class="tooltip-bottom" v-if="$nova.hasRight(right, 'edit')">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                              @click="editVerify(v)"
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Verification</span>
                        </v-tooltip>

                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="shadow-off"
                              small
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="viewVerify(v)"
                            >
                              <v-icon color="">mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>View Verification</span>
                        </v-tooltip>
                        <v-tooltip
                          bottom
                          content-class="tooltip-bottom"
                          v-if="v.status && $nova.hasRight(right, 'enable/disable')"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              color="error"
                              v-bind="attrs"
                              v-on="on"
                              @click="enableVerify(v._id)"
                            >
                              <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Disable</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom" v-else-if="$nova.hasRight(right, 'enable/disable')">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              color="success"
                              v-bind="attrs"
                              v-on="on"
                              @click="disableVerify(v._id)"
                            >
                              <v-icon>mdi-check-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Enable</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </v-simple-table> -->

            <DataTable
              :headers="headers"
              :items="formatedData"
              :enableslot="['action', 'areaname']"
              :loading="loading"
            >
              <template v-slot:areaname="{ item }">
                {{ item.areaname.name }}
              </template>

              <template v-slot:action="{ item }">
                <div class="d-flex justify-center">
                  <v-tooltip
                    bottom
                    content-class="tooltip-bottom"
                    v-if="$nova.hasRight(right, 'edit')"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="editVerify(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Verification</span>
                  </v-tooltip>

                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="shadow-off"
                        small
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="viewVerify(item)"
                      >
                        <v-icon color="">mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>View Verification</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    content-class="tooltip-bottom"
                    v-if="
                      item.status && $nova.hasRight(right, 'enable/disable')
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="enableVerify(item._id)"
                      >
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Disable</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    content-class="tooltip-bottom"
                    v-else-if="$nova.hasRight(right, 'enable/disable')"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                        @click="disableVerify(item._id)"
                      >
                        <v-icon>mdi-check-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Enable</span>
                  </v-tooltip>
                </div>
              </template>
            </DataTable>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <v-dialog v-model="verifyDialog" max-width="600" persistent>
      <v-card>
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text" v-if="verifyObj._id"
            >Update Verification</span
          >
          <span class="subtitle-1 white--text" v-else>Create Verification</span>

          <v-spacer></v-spacer>
          <v-btn icon @click="verifyDialog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="mt-4 pt-4 px-4">
          <div>
            <v-row>
              <v-col cols="12" class="mb-0 py-1">
                <lb-string
                  label="Name"
                  v-model="verifyObj.name"
                  :error="errors['name']"
                />
              </v-col>
              <v-col cols="12" class="mb-0 py-1">
                <lb-string
                  label="Code"
                  v-model="verifyObj.code"
                  :error="errors['code']"
                />
              </v-col>
              <v-col cols="12" class="mb-0 py-1">
                <lb-string
                  label="Description"
                  v-model="verifyObj.description"
                />
              </v-col>
              <v-col cols="12" class="my-0 py-1">
                <lb-dropdown
                  label="Area List"
                  v-model="verifyObj.area"
                  :items="areaList"
                  itemtext="name"
                  itemvalue="_id"
                  :error="errors['area']"
                  multiple
                />
              </v-col>
              <v-col cols="12" class="my-0 py-1">
                <lb-dropdown
                  label="Input Parameters"
                  v-model="verifyObj.parameters"
                  :items="inputParaList"
                  itemtext="name"
                  itemvalue="_id"
                  multiple
                />
              </v-col>
              <v-col cols="12" class="mb-0 py-1">
                <v-btn
                  small
                  outlined
                  text
                  class="ml-2 shadow-off"
                  @click="addFileCom()"
                >
                  Add File
                  <v-icon right dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" class="mb-0 py-1 mb-6">
                <v-card v-for="(colums, i) in fileTotalArr" :key="i">
                  <v-card-text>
                    <div>
                      File Name:
                      <v-chip
                        class="ma-2"
                        close
                        color="primary"
                        label
                        text-color="white"
                        small
                        @click:close="removeFile(i)"
                      >
                        <v-icon left> mdi-file </v-icon>
                        {{ getFileName(colums.id) }}
                      </v-chip>
                    </div>
                    <div>Mandatory Columns:</div>
                    <span v-for="m in colums.mandatorycolumns" :key="m">
                      <v-chip class="ma-1" label small> {{ m }} </v-chip>
                    </span>
                    <div>Optional Columns:</div>
                    <span v-for="o in colums.optionalcolumns" :key="o">
                      <v-chip class="ma-1" label small> {{ o }} </v-chip>
                    </span>
                  </v-card-text>
                  <v-divider></v-divider>
                </v-card>
              </v-col>

              <v-col cols="12" class="mb-0 py-1">
                <lb-string
                  label="observation"
                  v-model="verifyObj.observation"
                />
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            v-if="verifyObj._id"
            @click="updateVerify()"
            >Update</v-btn
          >
          <v-btn small color="primary" v-else @click="creatVerify()"
            >Create</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addFileDilog" max-width="600" persistent>
      <v-card class="">
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text">Add File</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="addFileDilog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="mt-4 pt-4">
          <v-row>
            <v-col cols="12" class="my-0 py-1">
              <v-checkbox
                label="Mandatory File"
                v-model="mandatoryFile"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-dropdown
                label="File Master"
                v-model="fileId"
                :items="fileList"
                itemtext="name"
                itemvalue="_id"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <v-list flat>
                <v-list-item-group active-class="">
                  <v-list-item v-for="colums in columsList" :key="colums.name">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          :value="colums.name"
                          v-model="columTotal"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{ colums.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          colums.type
                        }}</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-spacer></v-spacer>

                      <v-switch
                        v-if="columShows(colums.name)"
                        v-model="addOptional"
                        color="success"
                        label="Optional"
                        :value="colums.name"
                      ></v-switch>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="addFileDetisl()">Create</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="verifyViewDialog" max-width="1000" persistent>
      <v-card class="">
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text">Verification</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="verifyViewDialog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="px-0 mx-0 pb-2">
          <v-tabs v-model="tab" height="40">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab key="0" class="text-transform-none justify-start"
              ><v-icon class="mr-2">mdi-format-list-bulleted</v-icon>Basic
              information</v-tab
            >
            <v-tab
              key="1"
              v-if="getfiles > 0"
              class="text-transform-none justify-start"
              ><v-icon class="mr-2">mdi-file-multiple</v-icon>Files
            </v-tab>
            <v-tab
              key="2"
              v-if="getinputParam > 0"
              class="text-transform-none justify-start"
              ><v-icon class="mr-2">mdi-ballot-outline</v-icon>Input
              Parameters</v-tab
            >
          </v-tabs>
          <v-divider></v-divider>
          <v-tabs-items v-model="tab" class="">
            <v-tab-item key="0">
              <v-card class="shadow-off">
                <v-card-text class="">
                  <div class="mb-2">
                    <div class="d-flex align-center mb-2">
                      <span class="font-weight-bold mr-3">Code:</span>
                      <span>{{ verifyViewObj.code }}</span>
                    </div>
                    <div class="d-flex align-center mb-2">
                      <span class="font-weight-bold mr-3">Name:</span>
                      <span>{{ verifyViewObj.name }}</span>
                    </div>
                    <div class="d-flex align-center mb-2">
                      <span class="font-weight-bold mr-3">Area:</span>
                      <span>{{
                        verifyViewObj.area_detail &&
                        verifyViewObj.area_detail[0] &&
                        verifyViewObj.area_detail[0].name
                      }}</span>
                    </div>
                    <div class="d-flex align-center mb-2">
                      <span class="font-weight-bold mr-3">Description:</span>
                      <span>{{ verifyViewObj.description }}</span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item key="1">
              <v-card class="shadow-off">
                <v-card-text class="px-0">
                  <div
                    class="mb-2 ml-4"
                    v-for="(x, y) in verifyViewObj.files"
                    :key="y"
                  >
                    <div class="font-weight-bold">{{ x.name }}</div>

                    <div class="text--primary">Optional Columns :</div>
                    <v-chip
                      small
                      class="ml-2"
                      label
                      v-for="tag in x.optionalcolumns"
                      :key="tag"
                    >
                      {{ tag }}
                    </v-chip>
                    <div class="text--primary">Mandatory Columns :</div>
                    <v-chip
                      small
                      class="ml-1"
                      label
                      v-for="tag in x.mandatorycolumns"
                      :key="tag"
                    >
                      {{ tag }}
                    </v-chip>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item key="2">
              <v-card class="shadow-off">
                <v-card-text class="px-0">
                  <div class="mb-2 ml-4">
                    <ol>
                      <li
                        v-for="(c, d) in verifyViewObj.parameters_detail"
                        :key="d"
                      >
                        <span class="font-weight-black">{{ c.code }}</span> -
                        {{ c.name }}
                      </li>
                    </ol>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DataTable from "../../../../views/common/DataTable.vue";
export default {
  data() {
    return {
      right: "conserve_verification",
      fileId: null,
      addFileDilog: false,
      loading: false,
      seachList: null,
      verifyDialog: false,
      verifyViewDialog: false,
      verifyObj: {},
      verifyViewObj: {},
      tab: "",
      verifyAllList: null,
      verifyList: null,
      areaList: null,
      inputParaList: null,
      fileList: null,
      columsList: null,
      mandatoryFile: true,
      columTotal: [],
      columOptional: [],
      addMandatory: [],
      addOptional: [],
      fileTotalArr: [],
      errors: {},
      headers: [
        {
          value: "indexofno",
          text: "#",
          sortable: false,
          filterable: false,
          lock: true,
          visible: false,
        },
        {
          value: "code",
          text: "Code",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "name",
          text: "Name",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "description",
          text: "Description",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "areaname",
          text: "Area",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "created_at",
          text: "Created At",
          sortable: true,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "updated_at",
          text: "Updated At",
          sortable: true,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
          alignment: "text-center",
        },
      ],
      formatedData: [],
    };
  },
  components: {
    DataTable,
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  computed: {
    searchResult() {
      let tempList = this.verifyList;
      if (this.seachList != "" && this.seachList) {
        tempList = tempList.filter((item) => {
          return (
            item.name.toUpperCase().includes(this.seachList.toUpperCase()) ||
            item.code.toUpperCase().includes(this.seachList.toUpperCase())
          );
        });
      }
      return tempList;
    },
    getfiles() {
      return this.verifyViewObj.files ? this.verifyViewObj.files.length : 0;
    },
    getinputParam() {
      return this.verifyViewObj.parameters
        ? this.verifyViewObj.parameters.length
        : 0;
    },
    columMandatory() {
      let filtered = this.columTotal.filter((el) => {
        return this.addOptional.indexOf(el) === -1;
      });
      return filtered;
    },
  },
  methods: {
    formatdData() {
      let ObjectTemp = {};
      let ArrayTemp = [];
      this.verifyList.forEach((item, index) => {
        ObjectTemp = {
          __key: index,
          __formatedkey: index,
          _id: item._id,
          indexofno: index + 1,
          name: item.name,
          code: item.code,
          description: item.description,
          areaname: item.area_detail[0] || {}.name,
          area: [item.area_detail[0]?._id],
          created_at: item.created_at,
          updated_at: item.updated_at,
          status: item.status,
          files: item.files,
          parameters_detail: item.parameters_detail,
          parameters: item.parameters,
        };
        ArrayTemp.push(ObjectTemp);
      });
      this.formatedData = ArrayTemp;
    },
    refreshData() {
      this.loading = true;
      this.axios
        .post("/v2/conserve/verification/get")
        .then((dt) => {
          if (dt.data.status === "success") {
            this.verifyList = dt.data.data;
            this.axios
              .post("/v2/conserve/verification/getformdata")
              .then((dt) => {
                if (dt.data.status === "success") {
                  this.verifyAllList = dt.data.data;
                  this.areaList = this.verifyAllList[0].areas;
                  this.inputParaList = this.verifyAllList[0].inputparameters;
                  this.fileList = this.verifyAllList[0].files;
                  this.formatdData();
                } else return new Error(dt.data.message);
              })
              .catch((err) => {
                this.$store.commit(
                  "sbError",
                  err.message || err || "Unknown error!"
                );
                console.log(err);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addFileCom() {
      this.fileId = "";
      this.fileObj = {};
      this.addFileDilog = true;
    },
    columShows(name) {
      let ss = this.columTotal.filter((x) => x == name);
      return ss.length > 0 ? true : false;
    },
    addFileDetisl() {
      this.addFileDilog = false;
      let fileObj = {
        id: this.fileId,
        mandatorycolumns: this.columMandatory,
        optionalcolumns: this.addOptional,
        mandatory: this.mandatoryFile,
      };
      this.fileTotalArr.push(fileObj);
    },
    creatVerify() {
      this.loading = true;
      this.verifyObj.files = this.fileTotalArr;
      this.axios
        .post("/v2/conserve/verification/add", { data: this.verifyObj })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.verifyObj = {};
            this.fileTotalArr = [];
            this.fileObj = {};
            this.verifyDialog = false;
            this.$store.commit("sbSuccess", "verification add successfully");
            this.refreshData();
          } else {
            this.errors = {};
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            throw new Error(dt.data.message || "Error Adding Verification");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editVerify(item) {
      this.verifyObj = { ...item };
      this.fileTotalArr = [...item.files];
      this.verifyDialog = true;
      this.errors = {};
    },
    viewVerify(item) {
      this.verifyViewDialog = true;
      this.verifyViewObj = item;
    },
    updateVerify() {
      this.loading = true;
      this.verifyObj.files = this.fileTotalArr;
      this.axios
        .post("/v2/conserve/verification/edit/" + this.verifyObj._id, {
          data: this.verifyObj,
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.verifyObj = {};
            this.fileTotalArr = [];
            this.fileObj = {};
            this.verifyDialog = false;
            this.$store.commit("sbSuccess", "verification update successfully");
            this.refreshData();
          } else {
            this.errors = {};
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            throw new Error(dt.data.message || "Error Update Verification");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    enableVerify(id) {
      this.loading = true;
      this.axios
        .post("/v2/conserve/verification/disable/" + id)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "disable successfully");
            this.refreshData();
          } else
            throw new Error(dt.data.message || "Error Enable Verification");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    disableVerify(id) {
      this.loading = true;
      this.axios
        .post("/v2/conserve/verification/enable/" + id)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "enable successfully");
            this.refreshData();
          } else
            throw new Error(dt.data.message || "Error Disable Verification");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFileName(id) {
      let files = this.fileList.find((x) => x._id === id);
      return (files || {}).name;
    },
    removeFile(index) {
      let editedIndex = this.fileTotalArr.indexOf(index);
      this.fileTotalArr.splice(editedIndex, 1);
    },
  },
  watch: {
    fileId: function (id) {
      if (!id) {
        this.columsList = [];
        this.columTotal = [];
        this.addOptional = [];
      } else {
        this.axios
          .post("/v2/conserve/file/get/" + id)
          .then((dt) => {
            if (dt.data.status === "success") {
              this.columsList = dt.data.data[0].columns;
            } else throw new Error(dt.data.message || "Error Get File");
          })
          .catch((err) => {
            this.$store.commit(
              "sbError",
              err.message || err || "Unknown error!"
            );
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>